const { ApiProduct } = require('@imaginelearning/web-api-core');

(function() {
	angular
		.module('dashboard.search', [
			'manager.modal.factory',
			'search.common.localization',
			'global.search.service',
			'ui.notifications',
			'ui.loader',
			'ui.router',
			'ui.directives.scrollMore',
			'ui.directives.assistModeToggle',
			'ui.directives.fillWindowHeight',
			'date.range.drop.down',
			'api.application.user',
			'api.manager',
			'api.reporting'
		])
		.config(config)
		.controller('DashboardSearchController', controller)
		.directive('searchResults', directive);

	directive.$inject = ['$timeout', '$window'];

	function directive($timeout, $window) {
		return {
			restrict: 'A',
			link: function($scope, element, attrs) {
				function resize() {
					// match parent width
					$(element).width(
						$(element)
							.parent()
							.width()
					);
					// fill remaining height
					// get app header height
					var header = $("div [ui-view='header']"),
						headerHeight = header.height(),
						// get search view header height
						searchHeader = $('.search_header'),
						searchHeaderHeight = searchHeader.height();
					// add 72 ~ the 'results for' area and bottom padding
					$(element).height($window.innerHeight - (140 + searchHeaderHeight + headerHeight));
				}

				angular.element($window).bind('resize', resize);
				element.on('$destroy', function() {
					angular.element($window).off('resize', resize);
				});

				$timeout(function() {
					resize();
				}, 0);
			}
		};
	}

	config.$inject = ['$stateProvider'];
	function config($stateProvider) {
		$stateProvider.state('dashboard.search', {
			url: '/search?query&sources',
			controller: controller,
			controllerAs: 'model',
			templateUrl: 'dashboards/search/search.html',
			data: {
				bodyClass: 'dashboard',
				contentClass: 'search'
			}
		});
	}

	controller.$inject = [
		'$scope',
		'$q',
		'$state',
		'$localStorage',
		'GlobalSearchService',
		'DateUtils',
		'CurrentDate',
		'NotificationFactory',
		'ApplicationUserService',
		'UserRole',
		'managerAPIHelpers',
		'ManagerAPI',
		'ReportingAPI',
		'ManagerModalFactory'
	];

	function controller(
		$scope,
		$q,
		$state,
		$localStorage,
		GlobalSearchService,
		DateUtils,
		CurrentDate,
		NotificationFactory,
		ApplicationUserService,
		UserRole,
		managerAPIHelpers,
		ManagerAPI,
		ReportingAPI,
		ManagerModalFactory
	) {
		function emptyResults() {
			return {
				organizations: 0,
				groups: 0,
				users: 0,
				students: 0
			};
		}

		var model = this,
			lastQuery,
			defaultPageSize = 50,
			templateString = 'dashboards/search/partials/_ITEM_TYPE_.html';

		// props
		model.GlobalSearchService = GlobalSearchService;

		model.query = '';
		model.busy = false;
		model.error = false;
		model.initialized = false;
		model.noResults = false;
		model.movedToolsBannerProps = { product: ApiProduct.ILE };

		model.resultCounts = emptyResults();
		model.pageCount = 0;
		model.offset = 0;
		model.limit = defaultPageSize;
		model.searchResults = [];
		model.currentShowingCount = 0;
		model.returnLink = '';

		// methods
		model.setSearchScope = setSearchScope;
		model.setReturnState = setReturnState;
		model.stateChangeSuccess = stateChangeSuccess;
		model.getTemplateUrl = getTemplateUrl;
		model.totalCount = totalCount;
		model.reconcileParams = reconcileParams;
		model.search = search;

		model.editItem = editItem;

		function editItem(item, itemType) {
			ManagerModalFactory.edit(itemType, item.id);
		}

		var searchTerms;

		function reset() {
			searchTerms = undefined;
			model.resultCounts = emptyResults();
			model.pageCount = 0;
			model.offset = 0;
			model.limit = defaultPageSize;
			model.searchResults = [];
			model.currentShowingCount = 0;
		}

		reset();

		function setSearchScope(newScope) {
			// all
			if (!newScope) {
				GlobalSearchService.sources = GlobalSearchService.emptySources();
				model.currentShowingCount = totalCount();
			}
			// specified
			else {
				GlobalSearchService.sources.organizations = newScope === 'orgs';
				GlobalSearchService.sources.groups = newScope === 'groups';
				GlobalSearchService.sources.users = newScope === 'users';
				GlobalSearchService.sources.students = newScope === 'students';
				model.currentShowingCount = model.resultCounts[newScope === 'orgs' ? 'organizations' : newScope];
			}
			reconcileParams();
		}

		function setReturnState(fromState, fromParams) {
			if (!model.returnLink || !model.returnLink.length) {
				// default return state to user's dash
				var name =
					fromState.name && !fromState.name.match('signin') && !fromState.name.match('search')
						? fromState.name
						: 'dashboard.user';
				var params =
					fromParams && fromState.name && !fromState.name.match('signin') && !fromState.name.match('search')
						? fromParams
						: { id: ApplicationUserService.getUser().id };
				var tmp = 'ROUTE_NAME(ROUTE_PARAMS)';
				model.returnLink = tmp.replace('ROUTE_NAME', name).replace('ROUTE_PARAMS', JSON.stringify(params));
			}
		}

		$scope.$on('$stateChangeSuccess', stateChangeSuccess);

		function stateChangeSuccess(event, toState, toParams, fromState, fromParams) {
			if (toState.name && toState.name === 'dashboard.search' && toParams && toParams.query) {
				var newSearch = false;
				setReturnState(fromState, fromParams);

				// reset results & params if this is a new search
				if (!angular.equals(lastQuery, model.query)) {
					lastQuery = model.query;
					newSearch = true;
				}

				// decode query
				model.query = decodeURIComponent(toParams.query);
				if (!angular.equals(GlobalSearchService.query, model.query)) {
					GlobalSearchService.query = model.query;
				}

				// reconcileParams();
				GlobalSearchService.sources =
					toParams.sources && toParams.sources.length
						? GlobalSearchService.paramsToSources(toParams.sources)
						: GlobalSearchService.sources;

				// search
				search(newSearch);
			}
		}

		function totalCount() {
			return model.resultCounts.organizations + model.resultCounts.groups + model.resultCounts.users + model.resultCounts.students;
		}

		function reconcileParams() {
			var sources = [
				GlobalSearchService.sources.organizations ? 'o' : '',
				GlobalSearchService.sources.groups ? 'g' : '',
				GlobalSearchService.sources.users ? 'u' : '',
				GlobalSearchService.sources.students ? 's' : ''
			].join('');
			// update addressbar
			$state.go(
				$state.current.name,
				{
					query: encodeURIComponent(model.query),
					sources: sources
				},
				{ notify: false }
			);
		}

		function getTemplateUrl(itemType) {
			return templateString.replace('_ITEM_TYPE_', itemType);
		}

		function searchManagerAPI(newSearch) {
			// reset results & params if this is a new search
			if (newSearch) {
				reset();
			}

			var promises = [],
				params = {};

			// only get more if we are not already busy or current results count < total
			if (model.busy || !(!model.totalCount() || !model.searchResults || model.searchResults.length < model.currentShowingCount)) {
				return;
			}

			model.busy = true;
			model.noResults = false;

			if (GlobalSearchService.sources.organizations) {
				promises.push(
					ManagerAPI.Organization.paged(
						{
							search: model.query,
							offset: model.offset,
							limit: model.limit,
							desc: true,
							// sortby: 'name', // we don't want sorted - we want match order from server
							additionalFields: [
								'parentName',
								'lexileSettings',
								'fullOrganizationCount',
								'fullGroupCount',
								'fullUserCount',
								'fullStudentCount'
							]
						},
						true
					)
				);
			} else {
				// push an empty promise to maintain response order
				promises.push($q.when());
			}

			if (GlobalSearchService.sources.groups) {
				if (ApplicationUserService.getUserRole() === UserRole.teacher) {
					promises.push(
						ManagerAPI.User.groupsPaged(
							ApplicationUserService.getUser().id,
							{
								search: model.query,
								offset: model.offset,
								limit: model.limit,
								desc: true,
								// sortby: 'name', // we don't want sorted - we want match order from server
								additionalFields: ['parentName', 'userCount', 'studentCount', 'users']
							},
							true
						)
					);
				} else {
					promises.push(
						ManagerAPI.Group.paged(
							{
								search: model.query,
								offset: model.offset,
								limit: model.limit,
								desc: true,
								// sortby: 'name', // we don't want sorted - we want match order from server
								additionalFields: ['parentName', 'userCount', 'studentCount', 'users']
							},
							true
						)
					);
				}
			} else {
				// push an empty promise to maintain response order
				promises.push($q.when());
			}

			if (GlobalSearchService.sources.users) {
				promises.push(
					ManagerAPI.User.paged(
						{
							search: model.query,
							offset: model.offset,
							limit: model.limit,
							desc: true,
							// sortby: 'name', // we don't want sorted - we want match order from server
							additionalFields: ['studentCount']
						},
						true
					)
				);
			} else {
				// push an empty promise to maintain response order
				promises.push($q.when());
			}

			if (GlobalSearchService.sources.students) {
				promises.push(
					ManagerAPI.Student.paged(
						{
							search: model.query,
							offset: model.offset,
							limit: model.limit,
							desc: true,
							// sortby: 'name', // we don't want sorted - we want match order from server
							additionalFields: ['lexileSettings']
						},
						true
					)
				);
			} else {
				// push an empty promise to maintain response order
				promises.push($q.when());
			}

			$q.all(promises)
				.then(function(results) {
					if (results[0] && results[0].items && results[0].items.length) {
						results[0].items = _.map(results[0].items, function(item) {
							item.itemType = 'organization';
							item.source = 'organizations';
							return item;
						});
						model.resultCounts.organizations = model.resultCounts.organizations || results[0].totalCount;
						model.searchResults = model.searchResults.concat(results[0].items);
					}
					if (results[1] && results[1].items && results[1].items.length) {
						results[1].items = _.map(results[1].items, function(item) {
							item.itemType = 'group';
							item.source = 'groups';
							return item;
						});
						model.resultCounts.groups = model.resultCounts.groups || results[1].totalCount;
						model.searchResults = model.searchResults.concat(results[1].items);
					}
					if (results[2] && results[2].items && results[2].items.length) {
						results[2].items = _.map(results[2].items, function(item) {
							item.itemType = 'user';
							item.source = 'users';
							return item;
						});
						model.resultCounts.users = model.resultCounts.users || results[2].totalCount;
						model.searchResults = model.searchResults.concat(results[2].items);
					}
					if (results[3] && results[3].items && results[3].items.length) {
						results[3].items = _.map(results[3].items, function(item) {
							item.itemType = 'student';
							item.source = 'students';
							return item;
						});
						model.resultCounts.students = model.resultCounts.students || results[3].totalCount;
						model.searchResults = model.searchResults.concat(results[3].items);
					}
				})
				.catch(function(error) {
					model.error = NotificationFactory.error(error);
				})
				.finally(function() {
					if (model.offset === 0 && totalCount() === 0) {
						model.noResults = true;
					} else if (model.offset === 0 && totalCount() > 0) {
						model.currentShowingCount = totalCount();
					}

					model.busy = false;
					model.offset = model.offset + model.limit;
				});
		}

		function search(newSearch) {
			searchManagerAPI(newSearch);
		}
	}
})();
